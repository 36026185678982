/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Inria+Sans&family=Mulish:wght@300&family=Pacifico&family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inria Sans", sans-serif;
}
::-webkit-scrollbar {
  display: none;
}


input[type="date"]:not(.has-value)::before {
  color: white;
  content: attr(placeholder);
}

input[type="date"]:not(.white-placeholder)::before{
  color: #adadad;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  display: none;
}

/* font-family: 'Inria Sans', sans-serif;
font-family: 'Mulish', sans-serif;
font-family: 'Pacifico', cursive;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif; */
